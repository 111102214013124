import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Stack, Typography } from '@mui/material';
// components

export default function BlogPage() {
  return (
    <>
      <Helmet>
        <title> Blog </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blog
          </Typography>
        </Stack>

        <Card>
          We're excited to announce that our blog is coming soon!.
        </Card>
      </Container>
    </>
  );
}
