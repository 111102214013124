import {Helmet} from 'react-helmet-async';
import React, {useState} from 'react';
// @mui
import {
    Box,
    Button,
    Card,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
// components
import AgeRangeSpending from 'src/components/age-range-spending/AgeRangeSpending';
// sections
import {AppNetWorthChart,} from '../sections/@dashboard/app';

import {generateResults} from '../utils/generateChartResults'
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [currentAge, setCurrentAge] = useState(30);
  const [timeLine, setTimeLine] = useState(20);
  const [inflationRate, setInflationRate] = useState(2.0);
  const [currentSavings, setCurrentSavings] = useState(50000);
  const [conservativePercentage, setConservativePercentage] = useState(75);
  const [ageRangeSpending, setAgeRangeSpending] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [market, setMarket] = useState("dow");
    const [recoveryStrategy, setRecoveryStrategy] = useState("none");
    const [retirementAge, setRetirementAge] = useState(30);
    const [endAge, setEndAge] = useState(80);
    const [calculations, setCalculations] = useState({});
    const [currentCalculation, setCurrentCalculation] = useState({});
    const [showCalculation, setShowCalculation] = useState(false);
    const [selectedCalculation, setSelectedCalculation] = useState('');
    const [savingsPerYear, setSavingsPerYear] = useState(100);
    const [simulationResults, setSimulationResults] = useState({});
    const [yearlyBreakdown, setYearlyBreakdown] = useState([{age: 25, year: 2020, spending: 50000, saving: 20000, total: 100000}]);

  const handleSubmit = (e) => {
    setCurrentAge(e.target.value)
  };

    const handleRetirementAgeChange = (e) => {
        setRetirementAge(e.target.value)
    };

    const handleEndAgeChange = (e) => {
        setEndAge(e.target.value)
    };
  const handleTimeLineChange = (e) => {
      setTimeLine(e.target.value);
  };


    // eslint-disable-next-line no-unused-vars
    const handleMarketChange = (e) => {
        setMarket(e.target.value);
    };

    const handleRecoveryStrategyChange = (e) => {
        setRecoveryStrategy(e.target.value);
    };

    const calculate = async () => {
        const host = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8080' : process.env.REACT_APP_API_HOST;
        const url = `${host}/api/ByAge`;
        const body = {
            "currentAge": currentAge,
            "retirementAge": retirementAge,
            "timePeriod": timeLine,
            "lifeExpectancy": endAge,
            "spendDuringRetirementPreInflation": 200000,
            "inflationRate": inflationRate,
            "startingAmount": currentSavings,
            "recoveryStrategy": recoveryStrategy,
            "retirementConservativePercentage": conservativePercentage / 100.0,
            "yearlySpendingList": ageRangeSpending.map((range) => {
                return {
                    "startAge": range.start,
                    "endAge": range.end,
                    "spending": range.spending,
                    "downturnSpending": range.recoverySpending
                };
            })
        };
        const response = await fetch(url, {
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(body)
        });

        const data = await response.json();
        const results = generateResults(data);
        setSimulationResults(data);
        setCalculations(results);
        setSavingsPerYear(data.medianResults[0].saving);
        setCurrentCalculation(results.median);
        setYearlyBreakdown(data.medianResults)
        setShowCalculation(true);
        setSelectedCalculation(1)
    }

    const handleCalculationChange = (event, newValue) => {
        setSelectedCalculation(newValue);
        let calculation;
        switch (newValue) {
            case 0:
                setSavingsPerYear(simulationResults.maxResults[0].saving);
                setYearlyBreakdown(simulationResults.maxResults)
                calculation = calculations.worstCase;
                break;
            case 1:
                setSavingsPerYear(simulationResults.medianResults[0].saving);
                setYearlyBreakdown(simulationResults.medianResults)
                calculation = calculations.median;
                break;
            default:
                setSavingsPerYear(simulationResults.minResults[0].saving);
                setYearlyBreakdown(simulationResults.minResults)
                calculation = calculations.bestCase
        }
        setCurrentCalculation(calculation);
    }

    const handleCurrentSavingsChange = (e) => {
        setCurrentSavings(e.target.value)
    }

    const handleInflationRateChange = (e) => {
        setInflationRate(e.target.value);
    }

    const handleConservativePercentageChange = (e) => {
        setConservativePercentage(e.target.value);
    }

    const handleAgeRangeSpending = (ranges) => {
        setAgeRangeSpending(ranges);
    }




    const labels = currentCalculation.labels;
    const values = currentCalculation.values;
    return (
    <>
      <Helmet>
        <title> Retirement by Age Simulator </title>
      </Helmet>

      <Container maxWidth="xl">
        <Card sx={{padding: 2}}>
            <Typography sx={{ mb: 5 }}>
                The Retirement By Age Simulator is a tool that helps individuals plan for the age at which they can retire by
                simulating past market results. The simulator takes into account a variety of factors, including your age,
                income, current savings, and retirement goals. It then uses this information to create a personalized retirement
                savings plan.
            </Typography>
            <Typography>
                However, it is important to note that the simulator is just a tool. It is not a substitute for professional
                financial advice. If you are serious about planning for retirement, you should speak with a qualified
                financial advisor.
            </Typography>
            <Typography>
              To use the Retirement By Age Simulator, simply enter your information into the online form.
              The simulator will then generate a report that shows you how much you need to save,
              how your savings will be affected by different factors, and when you can retire.
            </Typography>
        </Card>

          <Box
              sx={{
                paddingTop: 2,
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              component="form">
          <div>
            <TextField required id="current-age" label="Current Age" onChange={handleSubmit} value={currentAge} />
            <TextField required id="retirement-age" label="Retirement Age" value={retirementAge} onChange={handleRetirementAgeChange} />
            <TextField required id="end-age" label="End Age" value={endAge} onChange={handleEndAgeChange} />
            <TextField required id="current-savings" label="Current Savings" value={currentSavings} onChange={handleCurrentSavingsChange} />
            <TextField required id="inflation-rate" label="Inflation Rate" value={inflationRate} onChange={handleInflationRateChange} />
            <TextField required id="percent-conservative" label="Conservative Percentage" onChange={handleConservativePercentageChange} value={conservativePercentage} />
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="recovery-strategy-label">Recovery Strategy</InputLabel>
                  <Select
                      labelId="recovery-strategy-label"
                      id="recovery-strategy"
                      value={recoveryStrategy}
                      label="Recovery Strategy"
                      onChange={handleRecoveryStrategyChange}
                  >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="oneYear">One Year</MenuItem>
                      <MenuItem value="twoYear">Two Year</MenuItem>
                      <MenuItem value="full">Full Recovery</MenuItem>
                  </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="time-line-label">Market History To Simulate</InputLabel>
                  <Select
                      labelId="time-line-label"
                      id="time-line"
                      value={timeLine}
                      label="Time Line"
                      onChange={handleTimeLineChange}
                  >
                      <MenuItem value={5}>Ten</MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                      <MenuItem value={40}>Forty</MenuItem>
                  </Select>
              </FormControl>
          </div>



          <AgeRangeSpending retirementAge={retirementAge} onChange={handleAgeRangeSpending} />
            <Button onClick={calculate}>Calculate</Button>
        </Box>

          { showCalculation &&
              <div>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={selectedCalculation} onChange={handleCalculationChange} aria-label="basic tabs example">
                          <Tab label="Worst" />
                          <Tab label="Median"/>
                          <Tab label="Best" />
                      </Tabs>
                  </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AppNetWorthChart
              title={`Savings per year: ${savingsPerYear}`}
              chartLabels={labels}
              chartData={[
                {
                  name: 'Savings',
                  type: 'line',
                  fill: 'solid',
                  data: values,
                }
              ]}
            />
          </Grid>
        </Grid>
                Yearly Breakdown
                  <TableContainer>
                      <Table sx={{ minWidth: 450 }}>
                          <TableHead>
                              <TableRow>
                                  <TableCell>Age</TableCell>
                                  <TableCell>Year</TableCell>
                                  <TableCell>Spending</TableCell>
                                  <TableCell>Yearly Savings</TableCell>
                                  <TableCell>Total</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {yearlyBreakdown.map((row) => {
                                  const { currentAge, year, spending, saving, end } = row;
                                  return (
                                      <TableRow hover key={currentAge} tabIndex={-1}>
                                          <TableCell component="th" scope="row" padding="none">
                                              <Stack direction="row" alignItems="center" spacing={2}>
                                                  <Typography variant="subtitle2" noWrap>
                                                      {currentAge}
                                                  </Typography>
                                              </Stack>
                                          </TableCell>
                                          <TableCell align="left">{year}</TableCell>
                                          <TableCell align="left">{spending}</TableCell>
                                          <TableCell align="left">{saving}</TableCell>
                                          <TableCell align="left">{end}</TableCell>
                                      </TableRow>
                                  );
                              })}
                          </TableBody>
                      </Table>
                  </TableContainer>



              </div>


          }

      </Container>
    </>
  );
}