function generate(data) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return {
    labels: data.map((record) => record.year + currentYear),
    values: data.map((record) => record.start)
  }
}

export function generateResults(data) {
  return {
    median: generate(data.medianResults),
    bestCase: generate(data.minResults),
    worstCase: generate(data.maxResults)
  };
}


