import React, { useState, useEffect } from 'react';

// @mui
import {TextField, Fab, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Iconify from "../iconify";

export default function AgeRangeSpending(pro) {
    let [ranges, setRanges] = useState([{start: 65, end: 80, spending: 55000, recoverySpending: 55000}]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        ranges[0].start = pro.retirementAge;
        refreshRanges(ranges);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pro.retirementAge]);

    const handleClick = () => {
        const newRanges = [];
        for(var i = 0; i < ranges.length; i++){
            newRanges.push(ranges[i]);
        }
        const previousEnd = parseInt(ranges[ranges.length-1].end);
        newRanges.push({start: previousEnd, end: previousEnd+10, spending: ranges[ranges.length-1].spending, recoverySpending: ranges[ranges.length-1].spending })
        refreshRanges(newRanges);
      };

    const refreshRanges = (newRanges) => {
        const freshRanges = newRanges.map(range => range);
        setRanges(freshRanges);
        pro.onChange(newRanges);
    };

    const handleStartAgeChange = (event, index) => {
        ranges[index].start = event.target.value;
        refreshRanges(ranges);
    }

    const handleEndAgeChange = (event, index) => {
        ranges[index].end = event.target.value;
        refreshRanges(ranges);
    }

    const handleSpendingChange = (event, index) => {
        ranges[index].spending = event.target.value;
        refreshRanges(ranges);
    }

    const handleRecoverySpendingChange = (event, index) => {
        ranges[index].recoverySpending = event.target.value;
        refreshRanges(ranges);
    }

    const removeDateRange = (event, index) => {
        ranges.splice(index, 1);
        refreshRanges(ranges);
    }

  return (
    <div>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Spending Age Ranges
        </Typography>
    {ranges.map((range, index) => {
        let startAge = range.start;

        if (index === 0) {
            startAge = pro.retirementAge;

        }

        let disabled = false;
        if (index < ranges.length-1) {
            disabled = true;
        }

        return (
            <div>
                <TextField required id="start-age" label="Start Age" value={startAge} disabled={disabled} onChange={(event) => handleStartAgeChange(event, index)} />
                <TextField required id="end-age" label="End Age" value={range.end} disabled={disabled} onChange={(event) => handleEndAgeChange(event, index)} />
                <TextField required id="spending-amount" label="Spending Per Year" value={range.spending} onChange={(event) => handleSpendingChange(event, index)} />
                <TextField required id="recovery-spending-amount" label="Recovery Spending Per Year" value={range.recoverySpending} onChange={(event) => handleRecoverySpendingChange(event, index)} />
                {index !== 0 && <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2, marginTop: 2, color: 'error.main' }} onClick={(event) => removeDateRange(event, index)} />}
            </div>
        )
    })}
        <Fab color="primary" aria-label="add" onClick={handleClick}>
            <AddIcon />
        </Fab>
    </div>
  );
}
