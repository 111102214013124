import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Typography, ListItemText, List, ListItem } from '@mui/material';


// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title> Advanced Retirement Simulator </title>
      </Helmet>

      <Container>
        <Card sx={{ position: 'relative' }}>
          <Typography gutterBottom>
            We appreciate your feedback! Please let us know how we can improve our website by emailing us at [email protected] We will respond to your email within 24 hours.

            Thank you for visiting our website!

            Here are some things you can tell us about:
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              <ListItem sx={{ display: 'list-item'}}>
                <ListItemText primary="General feedback about our website" />
              </ListItem>
              <ListItem sx={{ display: 'list-item'}}>
                <ListItemText primary="Suggestions for new features or content" />
              </ListItem>
              <ListItem sx={{ display: 'list-item'}}>
                <ListItemText primary="Errors or bugs you find on our website" />
              </ListItem>
              <ListItem sx={{ display: 'list-item'}}>
                <ListItemText primary="Any other questions or concerns you have" />
              </ListItem>
              <ListItem sx={{ display: 'list-item'}}>
                <ListItemText primary="We value your feedback and appreciate your help in making our website the best it can be!" />
              </ListItem>
            </List>
          </Typography>
        </Card>
      </Container>
    </>
  );
}
