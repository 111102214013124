import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Button, Container, Typography, ListItemText, List, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';


export default function HomePage() {
  return (
    <>
      <Helmet>
        <title> Advanced Retirement Simulator </title>
      </Helmet>

      <Container>
        <Card sx={{ position: 'relative', padding: 10 }}>
          <Typography variant="h1">
            Advanced Retirement Simulator
          </Typography>
          <Typography variant="subtitle1">
            Helping Individuals plan for Retirement with Advanced Calculators
          </Typography>

        </Card>
        <Card sx={{ position: 'relative', padding: 10 }}>
          <Typography gutterBottom>
            Welcome to advanced retirement calculations!
            We know that saving for retirement can be a daunting task, but it doesn't have to be. With our advanced calculations, you can get a more accurate picture of how much you need to save and how you can reach your retirement goals.
            Our calculations take into account a variety of factors, including your age, income, current savings, and retirement goals. We also provide you with a variety of tools and resources to help you make informed decisions about your retirement savings.
            We want to help you retire with confidence, and we believe that our advanced calculations can help you do just that. So what are you waiting for? Start using our calculations today and take control of your retirement savings!
            Here are some of the benefits of using our advanced calculations:
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              <ListItem sx={{ display: 'list-item', padding: 0}}>
                <ListItemText primary="See how your retirement savings will be affected by different factors, such as inflation and market volatility." />
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: 0}}>
                <ListItemText primary="Identify areas where you can improve your retirement savings." />
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: 0}}>
                <ListItemText primary="Get a more accurate picture of how much you need to save." />
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: 0}}>
                <ListItemText primary="Create a personalized retirement savings plan." />
              </ListItem>
            </List>
            While we want to make planning for retirement easier we are not a financial advisor. We are not qualified to provide financial advice, and we do not have the necessary knowledge or expertise to do so.
            If you are considering making any investment decisions, we urge you to speak with a qualified financial advisor. They will be able to help you understand your financial situation and develop a plan that is right for you
          </Typography>
        </Card>

        <Card>
          <Typography variant="h3">
            Popular Calculators
          </Typography>
          <Button
              component={RouterLink}
              to="/simulator/age" color="primary" variant="contained">By Age Simulator</Button>
        </Card>
      </Container>
    </>
  );
}
